import React, { useRef } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { removeTags } from '~c/utils/base'

import Layout from "../layouts/layout"
import Breadcrumbs from "~c/partials/breadcrumbs/breadcrumbs"

import "./case.scss"

interface Props {
  pageContext: {
    slug: string
    screenshots: string
    linksData: {
      prev: {
        slug: string
        title: string
      }
      next: {
        slug: string
        title: string
      }
    }
  }
  data: any
  location: {
    hash: string
    host: string
    hostname: string
    href: string
    key: string
    origin: string
    pathname: string
    port: string
    protocol: string
    search: string
    state: string
  }
}

export default ({ data, pageContext, location }: Props) => {
  const { linksData } = pageContext
  const { frontmatter, html } = data.markdownRemark
  const { nodes } = data.allFile
  const { siteMetadata } = data.site

  const sliderRef = useRef<Slider | null>(null)

  const settings = {
    dots: true,
    fade: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    lazyLoad: "pregressive",
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const slideNext = () => {
    if (sliderRef.current !== null) {
      sliderRef.current.slickNext()
    }
  }

  const slidePrev = () => {
    if (sliderRef.current !== null) {
      sliderRef.current.slickPrev()
    }
  }

  const onScrollHandler = e => {
    if (e.deltaY < 0) {
      slidePrev()
    } else if (e.deltaY > 0) {
      slideNext()
    }
  }

  const showScrollbar = () => {
    document.getElementsByTagName("html")[0].classList.remove("hide-scrollbar")
  }

  const hideScrollbar = () => {
    document.getElementsByTagName("html")[0].classList.add("hide-scrollbar")
  }

  const caseImage = getImage(frontmatter.image)

  return (
    <Layout
      meta={{
        title: removeTags(frontmatter.title),
        description: frontmatter.shortscription,
        canonical: location.pathname,
        schema: `{
          "@context": "https://schema.org",
          "@type": "CreativeWork",
          "author": {
            "@type": "Person",
            "name": "${siteMetadata.author}",
            "jobTitle": "${siteMetadata.job_title}"
          },
          "image": "${siteMetadata.siteUrl}${caseImage?.images.fallback?.src}",
          "name": "${frontmatter.title}"
          "dateCreated": "${frontmatter.date}",
          "keywords": "${frontmatter.stack.join(", ")}",
          "url": "${frontmatter.url}",
          "copyrightNotice": "${frontmatter.client}"
        }`,
        openGraph: {
          image: caseImage?.images.fallback?.src,
        },
      }}
    >
      <div className="view view-case bubbles">
        <Breadcrumbs location={location} parentClassName="text-center mb-16" />
        <section className="view-case__info">
          <div className="view-case__info-left">
            <div
              className="view-case__info-left-overlay"
              onMouseEnter={hideScrollbar}
              onMouseLeave={showScrollbar}
              onWheel={onScrollHandler}
            ></div>
            <Slider {...settings} ref={sliderRef}>
              {nodes.map(node => {
                const { childImageSharp, id, name } = node
                const image = getImage(childImageSharp)
                return (
                  <div className="slick-image-container" key={id}>
                    {image ? <GatsbyImage image={image} alt={name} /> : null}
                  </div>
                )
              })}
            </Slider>
          </div>
          <div className="view-case__info-right">
            <nav className="view-case__navigation">
              <Link
                to={`/cases/${linksData.prev.slug}`}
                title={linksData.prev.title}
                className="view-case__navigation-back"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="#e5e5e5"
                    d="M428.525 204.473C378.248 154.196 312.488 125.016 242 121.384V45.999a15 15 0 00-23.663-12.245l-212 150a14.998 14.998 0 00-.001 24.49l212 150a15 15 0 0023.663-12.245V271.43c110.796 6.382 207.512 82.961 238.269 190.609l2.309 8.08a15 15 0 0029.422-4.121v-60c.001-76.125-29.644-147.695-83.474-201.525zm-107.238 52.065c-30.371-10.311-62.093-15.539-94.287-15.539-8.284 0-15 6.716-15 15v61.012L40.97 195.999 212 74.988v61.011c0 8.284 6.716 15 15 15 134.609 0 245.191 104.836 254.382 237.149-35.513-61.765-92.482-108.655-160.095-131.61z"
                  />
                </svg>
              </Link>
              <Link
                to={"/cases"}
                title="All cases"
                className="view-case__navigation-cases"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 360.49 360.49"
                >
                  <path
                    fill="#e5e5e5"
                    d="M96.653 0H13.061C7.29 0 2.612 4.678 2.612 10.449v83.592c0 5.771 4.678 10.449 10.449 10.449h83.592c5.771 0 10.449-4.678 10.449-10.449V10.449C107.102 4.678 102.424 0 96.653 0z"
                  />
                  <path
                    fill="#e5e5e5"
                    d="M222.041 0h-83.592C132.678 0 128 4.678 128 10.449v83.592c0 5.771 4.678 10.449 10.449 10.449h83.592c5.771 0 10.449-4.678 10.449-10.449V10.449C232.49 4.678 227.812 0 222.041 0z"
                  />
                  <path
                    fill="#e5e5e5"
                    d="M96.653 125.388H13.061c-5.771 0-10.449 4.678-10.449 10.449v83.592c0 5.771 4.678 10.449 10.449 10.449h83.592c5.771 0 10.449-4.678 10.449-10.449v-83.592c0-5.771-4.678-10.449-10.449-10.449z"
                  />
                  <path
                    fill="#e5e5e5"
                    d="M222.041 125.388h-83.592c-5.771 0-10.449 4.678-10.449 10.449v83.592c0 5.771 4.678 10.449 10.449 10.449h83.592c5.771 0 10.449-4.678 10.449-10.449v-83.592c0-5.771-4.678-10.449-10.449-10.449z"
                  />
                  <path
                    fill="#e5e5e5"
                    d="M347.429 0h-83.592c-5.771 0-10.449 4.678-10.449 10.449v83.592c0 5.771 4.678 10.449 10.449 10.449h83.592c5.771 0 10.449-4.678 10.449-10.449V10.449C357.878 4.678 353.199 0 347.429 0z"
                  />
                  <path
                    fill="#e5e5e5"
                    d="M347.429 125.388h-83.592c-5.771 0-10.449 4.678-10.449 10.449v83.592c0 5.771 4.678 10.449 10.449 10.449h83.592c5.771 0 10.449-4.678 10.449-10.449v-83.592c0-5.771-4.679-10.449-10.449-10.449z"
                  />
                  <path
                    fill="#e5e5e5"
                    d="M96.653 256H13.061c-5.771 0-10.449 4.678-10.449 10.449v83.592c0 5.771 4.678 10.449 10.449 10.449h83.592c5.771 0 10.449-4.678 10.449-10.449v-83.592c0-5.771-4.678-10.449-10.449-10.449z"
                  />
                  <path
                    fill="#e5e5e5"
                    d="M222.041 256h-83.592c-5.771 0-10.449 4.678-10.449 10.449v83.592c0 5.771 4.678 10.449 10.449 10.449h83.592c5.771 0 10.449-4.678 10.449-10.449v-83.592c0-5.771-4.678-10.449-10.449-10.449z"
                  />
                  <path
                    fill="#e5e5e5"
                    d="M347.429 256h-83.592c-5.771 0-10.449 4.678-10.449 10.449v83.592c0 5.771 4.678 10.449 10.449 10.449h83.592c5.771 0 10.449-4.678 10.449-10.449v-83.592c0-5.771-4.679-10.449-10.449-10.449z"
                  />
                </svg>
              </Link>
              <Link
                to={`/cases/${linksData.next.slug}`}
                title={linksData.next.title}
                className="view-case__navigation-forward"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="#e5e5e5"
                    d="M505.664 183.754l-212-150A15 15 0 00270 45.999v75.385c-70.488 3.632-136.248 32.812-186.525 83.089C29.645 258.303 0 329.873 0 405.999v60C0 474.326 6.76 481 15.012 481a15.002 15.002 0 0014.411-10.88l2.309-8.08C62.488 354.391 159.204 277.813 270 271.43v74.569a15 15 0 0023.663 12.245l212-150a15 15 0 00.001-24.49zM300 317.011v-61.012c0-8.284-6.716-15-15-15-32.193 0-63.916 5.228-94.287 15.539-68.596 23.288-125.113 70.767-160.095 131.61C39.809 255.835 150.391 150.999 285 150.999c8.284 0 15-6.716 15-15V74.987l171.03 121.012z"
                  />
                </svg>
              </Link>
            </nav>
            <div className="view-case__info-title">
              <h1 className="text-secondary mb-24">{frontmatter.title}</h1>
            </div>
            <div className="view-case__info-description mb-24">
              <div
                className="view-text"
                dangerouslySetInnerHTML={{ __html: html }}
              ></div>
            </div>
            <div className="view-case__info-skills mb-24">
              <span className="view-case__label">Skills:</span>
              {frontmatter.stack.map((tech: string, i: number) => {
                return (
                  <div
                    className={`tag tag--technology tag--${tech} mb-8`}
                    key={i}
                  ></div>
                )
              })}
            </div>
            <div className="view-case__info-meta">
              <div className="view-case__info-date mb-12">
                <span className="view-case__label mb-16">Date:</span>{" "}
                {frontmatter.date}
              </div>
              <div className="view-case__info-client mb-12">
                <span className="view-case__label mb-16">Client:</span>
                {frontmatter.client}
              </div>
              <div className="view-case__info-link">
                <span className="view-case__label mb-16">Project link:</span>
                <a href={frontmatter.url} target="blank">
                  {frontmatter.url.replace(/(^\w+:|^)\/\//, "")}
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String, $screenshots: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(width: 600, formats: [AUTO])
          }
        }
        stack
        title
        shortscription
        slug
        date
        client
        url
      }
    }
    allFile(
      filter: { absolutePath: { regex: $screenshots } }
      sort: {fields: name}
    ) {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP])
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        job_title
        author
      }
    }
  }
`
